






































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import ReportService from "@/api/report-service";
import Company from "@/app/components/tabs/view-report/Company.vue";
import Documentation from "@/app/components/tabs/view-report/Documentation.vue";
import Stakeholders from "@/app/components/tabs/view-report/Stakeholders.vue";
import Structure from "@/app/components/tabs/view-report/Structure.vue";
import Principles from "@/app/components/tabs/view-report/Principles.vue";
import Certification from "@/app/components/tabs/view-report/Certification.vue";
import ReportItem from "@/app/components/reports/ReportItem.vue";
import FlagSectionOrPrincipleModal from "@/app/components/modals/FlagSectionOrPrincipleModal.vue";
import CompleteReviewModal from "@/app/components/modals/CompleteReviewModal.vue";
import ReviewConfirmationModal from "@/app/components/modals/ReviewConfirmationModal.vue";
import SendBackModal from "@/app/components/modals/SendBackModal.vue";
import CertifyReviewModal from "@/app/components/modals/CertifyReviewModal.vue";
import ReviewActivityModal from "@/app/components/modals/ReviewActivityModal.vue";
import UploadSignatureModal from "@/app/components/modals/UploadSignatureModal.vue";
import ReassignReviewerModal from "@/app/components/modals/ReassignReviewerModal.vue";
import ViewMessageModal from "@/app/components/modals/ViewMessageModal.vue";
import ReportCertification from "@/app/components/review/ReportCertification.vue";
import { formatDate } from "../../../utils/helpers";
import generatePDFBlob from "../../../utils/generatePDFBlob";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
    REGULATOR_APPROVAL_IN_PROGRESS = "RegulatorApprovalInProgress",
}

enum IReportRegulatorApprovalStatus {
    PENDING = "Pending",
    REVIEW_IN_PROGRESS = "ReviewInProgress",
    REVIEWED = "Reviewed",
    CERTIFICATION_ISSUED = "CertificationIssued",
    CERTIFICATION_DENIED = "CertificationDenied",
}

interface Review {
    id: string;
    name: string;
    type: string;
    status: string;
    flags: any[];
    complianceStatus?: string;
    documentProvided?: boolean;
    explanationProvided?: boolean;
}

export default Vue.extend({
    components: {
        Company,
        Documentation,
        Stakeholders,
        Structure,
        Principles,
        Certification,
        ReportItem,
        FlagSectionOrPrincipleModal,
        CompleteReviewModal,
        ReviewConfirmationModal,
        SendBackModal,
        CertifyReviewModal,
        ReviewActivityModal,
        UploadSignatureModal,
        ReassignReviewerModal,
        ViewMessageModal,
        ReportCertification,
    },
    data() {
        const namesMap: { [key: string]: string } = {
            companyDetails: "Company Details",
            governanceStructures: "Governance structures",
            governanceStakeholders: "Governance Stakeholder",
            documentation: "Documentation",
            execution: "Execution",
        };
        const reviews: Review[] = ["companyDetails", "governanceStructures", "governanceStakeholders", "documentation", "execution"].map((id) => {
            return { id, name: namesMap[id], type: "section", status: "unreviewed", flags: [] };
        });
        const initialPrinciplesReview: Review[] = Array.from(Array(28)).map((_, index) => {
            return {
                id: `principle${index + 1}`,
                name: `Principle ${index + 1}`,
                type: "principle",
                status: "unreviewed",
                flags: [],
                complianceStatus: "",
                documentProvided: false,
                explanationProvided: false,
            };
        });
        reviews.splice(4, 0, ...initialPrinciplesReview);

        return {
            IReportStatus,
            IReportRegulatorApprovalStatus,
            count: 0,
            defaultReport: {
                reportInformation: {
                    reportStatus: null,
                    reportCode: "",
                    currentDeskUser: {
                        email: "",
                        firstName: "",
                        id: "",
                        lastName: "",
                        profileImgId: "",
                        userName: "",
                    },
                    signatoryIds: [],
                    regulatorPushCount: 0,
                    regulatorApprovalStatus: "",
                    currentLevel: 0,
                    levelStatus: 0,
                },
                companyDetails: {},
                licenses: [],
                policies: [],
                registrarCompanies: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditors: [],
                charters: [],
                structure: {
                    commitees: [],
                    structurePositions: [],
                },
                principles: [],
                signatures: [],
                sectionReviews: [],
                certifierComment: {},
            },
            isLoading: false,
            DateTime,
            tabs: [
                { text: "Company details", value: "company", component: "company" },
                { text: "Governance Structures", value: "structure", component: "structure" },
                { text: "Governance Stakeholders", value: "stakeholders", component: "stakeholders" },
                { text: "Documentation", value: "documentation", component: "documentation" },
                { text: "Principles", value: "principles", component: "principles" },
                { text: "Execution", value: "certification", component: "certification" },
            ],
            isStartingReview: false,
            viewMode: "view-only",
            flagModalUsage: "",
            showReviewOptions: false,
            activeTab: "company",
            reviews,
            reviewFlags: [] as any[],
            currentSectionData: {},
            isReviewingSectionOrPrinciple: false,
            isCancellingReview: false,
            haveFetchedFines: false,
            finesAndPenalties: [] as any[],
            isIssuingCertification: false,
            showReviewActivityOptions: true,
        };
    },
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
            reportData: "reports/getReport",
        }),
        report(): any {
            return {
                ...this.defaultReport,
                ...this.reportData,
            };
        },
        currentTab(): { text: string; value: string; component: string } {
            const tab = this.activeTab || (this.$route.query.tab as string);
            const res = this.tabs.find((tabItem: any) => tabItem.value === tab);
            return res ?? this.tabs[0];
        },
        numberOfCheckedSections(): number {
            return this.reviews.filter((review) => review.status === "checked").length;
        },
        numberOfFlaggedSections(): number {
            return this.reviews.filter((review) => review.status === "flagged" && review.flags.length > 0).length;
        },
        userIsAdmin(): boolean {
            return this.profileData.roles.includes("Admin");
        },
        userCanReviewReport(): boolean {
            return (
                this.report.reportInformation.currentDeskUser.email === this.profileData.email &&
                (this.report.reportInformation.regulatorApprovalStatus === IReportRegulatorApprovalStatus.REVIEW_IN_PROGRESS ||
                    this.report.reportInformation.regulatorApprovalStatus === IReportRegulatorApprovalStatus.PENDING ||
                    this.showCertificationDeniedActions)
            );
        },
        userCanCertifyReport(): boolean {
            return this.report.reportInformation.currentDeskUser.email === this.profileData.email && this.report.reportInformation.regulatorApprovalStatus === IReportRegulatorApprovalStatus.REVIEWED;
        },
        showCertificationDeniedActions(): boolean {
            return (
                this.report.reportInformation.regulatorApprovalStatus === IReportRegulatorApprovalStatus.CERTIFICATION_DENIED &&
                this.report.reportInformation.currentDeskUser.email === this.profileData.email
            );
        },
        showCurrentDeskUser(): boolean {
            return true;
        },
        showSectionsReviewed(): boolean {
            return (
                this.report.reportInformation.regulatorApprovalStatus === IReportRegulatorApprovalStatus.REVIEW_IN_PROGRESS &&
                this.viewMode === "view-only" &&
                (this.numberOfFlaggedSections > 0 || this.numberOfCheckedSections > 0)
            );
        },
        showEllipsis(): boolean {
            return (
                ["Pending", "ReviewInProgress", "Reviewed", "CertificationDenied"].includes(this.report.reportInformation.regulatorApprovalStatus) &&
                (this.userCanCertifyReport || this.userCanReviewReport)
            );
        },
    },
    methods: {
        ...mapActions({
            fetchReport: "reports/fetchReportById",
            beginReview: "reports/startReview",
        }),
        formatDate,

        async fetchReportById() {
            const { id } = this.$route.params;
            this.isLoading = true;
            try {
                await this.fetchReport(id);
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },

        switchReportTab(tab: string) {
            if (this.activeTab === tab) return;
            this.activeTab = tab;
        },

        async startReview() {
            this.isStartingReview = true;
            const { id } = this.$route.params;
            try {
                const response = await this.beginReview(id);
                if (response && response.message) {
                    this.report.reportInformation.regulatorApprovalStatus = IReportRegulatorApprovalStatus.REVIEW_IN_PROGRESS;
                    this.viewMode = "reviewing";
                }
                this.isStartingReview = false;
            } catch {
                this.isStartingReview = false;
            }
        },

        async continueReview() {
            if (this.report.reportInformation.regulatorApprovalStatus === IReportRegulatorApprovalStatus.CERTIFICATION_DENIED) {
                await this.startReview();
            }
            this.viewMode = "reviewing";
        },

        addOrViewSectionFlags(sectionId: string, usage = "flag") {
            this.currentSectionData = this.reviews.find((section) => section.id === sectionId) as any;
            this.flagModalUsage = usage;
            this.$bvModal.show("modal-flag-section-or-principle");
        },

        async checkOrUncheckSection({
            sectionId,
            status,
            flags = [],
            complianceStatus = "",
            explanationProvided = false,
            documentProvided = false,
        }: {
            sectionId: string;
            status: string;
            flags: any[];
            complianceStatus?: string;
            explanationProvided?: boolean;
            documentProvided?: boolean;
        }) {
            this.isReviewingSectionOrPrinciple = true;
            const { id: reportId } = this.$route.params;

            const sectionOrPrincipleToBeReviewed = this.reviews.find((review) => review.id === sectionId);

            let reviewFlags: any[] = [];
            let newFlags: any[] = [];
            if (status === "flagged" && flags.length > 0) {
                newFlags = flags.filter((flag) => flag.isNew);
                const sectionIndex = this.reviews.findIndex((review) => review.id === sectionId);
                const sectionFlags = this.reviews[sectionIndex].flags;
                const updatedFlags = flags
                    .filter((newFlag) => {
                        const flagIndex = sectionFlags.findIndex((oldFlag) => newFlag.id === oldFlag.id);
                        return flagIndex > -1 && newFlag.details !== sectionFlags[flagIndex].details;
                    })
                    .map(({ id, details }) => ({ id, details }));
                reviewFlags = [...newFlags.map(({ details }) => ({ details })), ...updatedFlags];
            }

            const body = {
                ...(sectionOrPrincipleToBeReviewed?.type === "section" && { sectionName: sectionId }),
                ...(sectionOrPrincipleToBeReviewed?.type === "principle" && { principleName: sectionId }),
                reviewAction: status === "unreviewed" ? "unchecked" : status,
                ...(reviewFlags && reviewFlags.length > 0 && { flagReasons: reviewFlags }),
                ...(sectionOrPrincipleToBeReviewed?.type === "principle" && complianceStatus && { complianceStatus }),
                ...(sectionOrPrincipleToBeReviewed?.type === "principle" && complianceStatus === "partiallycompliant" && { complianceStatus, documentProvided, explanationProvided }),
            };

            try {
                let response = null;
                if (sectionOrPrincipleToBeReviewed?.type === "section") {
                    response = await ReportService.reviewSection(reportId, body);
                } else if (sectionOrPrincipleToBeReviewed?.type === "principle") {
                    response = await ReportService.reviewPrinciple(reportId, body);
                }

                if (response && response.status === 200) {
                    const { flagIds } = response.data;
                    this.$bvToast.toast(response.data.message, { title: "Success", variant: "success", solid: true });
                    this.reviews = this.reviews.map((review) => {
                        if (review.id === sectionId) {
                            const flagsWithIds = flags.map((flag, index) => {
                                if (flag.isNew) {
                                    const indexOfFineId = index - flags.length + 1 + newFlags.length - 1;
                                    return { details: flag.details, id: flagIds[indexOfFineId] };
                                }
                                return flag;
                            });
                            return { ...review, status, ...(status === "flagged" && flags.length > 0 && { flags: flagsWithIds }) };
                        }
                        return review;
                    });
                    this.currentSectionData = this.reviews.find((section) => section.id === sectionId) as any;
                    if (status === "flagged") this.$bvModal.hide("modal-flag-section-or-principle");
                }
            } catch (error) {
                this.isReviewingSectionOrPrinciple = false;
            } finally {
                this.isReviewingSectionOrPrinciple = false;
            }
        },

        saveSectionFlags(payload: any) {
            this.checkOrUncheckSection({ sectionId: payload.id, status: "flagged", flags: payload.flags });
        },

        deleteFlag(payload: any) {
            const sectionIndex = this.reviews.findIndex((section) => section.id === payload.sectionId);
            this.reviews[sectionIndex].flags = this.reviews[sectionIndex].flags.filter((flag) => flag.id !== payload.flagId);
        },

        async getReportFlags(id: string) {
            try {
                const response = await ReportService.fetchReportFlags(id);
                if (response.status === 200) {
                    this.reviewFlags = response.data.map((flag: any) => {
                        return { id: flag.id, details: flag.details, sectionName: flag.sectonName };
                    });
                }
            } catch (error) {
                //
            }
        },

        closeReviewConfirmationModal() {
            this.viewMode = "view-only";
            this.$bvModal.hide("modal-review-confirmation");
        },

        async cancelReview() {
            this.isCancellingReview = true;
            const { id: reportId } = this.$route.params;
            try {
                const response = await ReportService.cancelReview(reportId);
                if (response && response.status === 200) {
                    this.reviews = this.reviews.map(({ name, id, type }) => {
                        return { name, id, type, flags: [], status: "unreviewed", documentProvided: false, explanationProvided: false };
                    });
                    this.$bvToast.toast(response.data.message, { title: "Success", variant: "success", solid: true });
                    this.report.reportInformation.regulatorApprovalStatus = "Pending";
                    this.viewMode = "view-only";
                    this.$bvModal.hide("modal-review-confirmation");
                }
                this.isCancellingReview = false;
            } catch {
                this.isCancellingReview = false;
            }
        },

        async refreshReportData() {
            this.viewMode = "view-only";
            const { id } = this.$route.params;
            await this.fetchReportById();
            await this.getReportFlags(id);
        },

        async getReportFines(reportId: string) {
            try {
                const response = await ReportService.getFines(reportId);
                if (response && response.status === 200 && response.data && response.data.length > 0) {
                    this.finesAndPenalties = [...response.data].map(({ id, basis, amount, incidentPrinciple }: any) => {
                        return { id, basis, amount, incidentPrinciple };
                    });
                }
                this.haveFetchedFines = true;
            } catch (error) {
                //
            }
        },

        deleteFine(fineId: string) {
            this.finesAndPenalties = this.finesAndPenalties.filter((fine) => fine.id !== fineId);
        },

        updateFines(fines: any[]) {
            this.finesAndPenalties = JSON.parse(JSON.stringify(fines));
        },

        async issueCertification() {
            this.isIssuingCertification = true;
            const { id } = this.$route.params;

            // Generate PDFs on the fly
            const formData = new FormData();
            const certificatePdf = await generatePDFBlob((this.$refs.certificatePdf as any).$el);
            formData.append("certificatePdf", certificatePdf, "certificatePdf"); // append certification pdf
            if (this.finesAndPenalties.length > 0) {
                const penaltyPdf = await generatePDFBlob((this.$refs.penaltyPdf as any).$el);
                formData.append("penaltyPdf", penaltyPdf, "penaltyPdf"); // append penalties pdf
            }

            try {
                const response = await ReportService.issueCertification(id, formData);
                if (response && response.status === 200) {
                    this.$bvToast.toast(response.data.message, { solid: true, variant: "success", title: "Success" });
                    this.$bvModal.hide("modal-certify-review");
                    await this.refreshReportData();
                }
                this.isIssuingCertification = false;
            } catch (error) {
                this.isIssuingCertification = false;
            }
        },

        changeReportReviewer(payload: any) {
            this.report.reportInformation.currentDeskUser = { ...payload.currentDeskUser };
        },
    },

    async mounted() {
        const { id } = this.$route.params;
        if (id) {
            await this.fetchReportById();
            await this.getReportFlags(id);
            await this.getReportFines(id);
        }

        this.reviews = this.reviews.map((section) => {
            const savedReview = this.report.sectionReviews.find((sectionReview: any) => sectionReview.name === section.id);
            const savedReviewFlags = this.reviewFlags.filter((flag) => flag.sectionName === section.id);
            if (savedReview) {
                return {
                    ...section,
                    // eslint-disable-next-line no-nested-ternary
                    status: savedReview.isChecked ? "checked" : savedReview.isFlagged ? "flagged" : "unreviewed",
                    ...(savedReviewFlags && savedReviewFlags.length > 0 && { flags: savedReviewFlags }),
                    ...(section.type === "principle" && { complianceStatus: savedReview?.complianceStatus?.toLowerCase() ?? "" }),
                    ...(section.type === "principle" &&
                        savedReview?.complianceStatus?.toLowerCase() === "partiallycompliant" && {
                            documentProvided: savedReview?.documentProvided ?? false,
                            explanationProvided: savedReview?.explanationProvided ?? false,
                        }),
                };
            }
            return section;
        });
        if (this.userCanCertifyReport) this.viewMode = "certifying";
    },
});
